import { useRouter } from "next/router";
import { useEffect } from "react";

declare global {
    interface Window {
        dataLayer: Record<string, string>[];
    }
}

export const gtmCustomEvent = (
    eventName: string,
    eventData: Record<string, string | undefined>
) => {
    window?.dataLayer?.push({
        event: eventName,
        ...eventData,
    });
};

export const gtmCTAEvent = (name: string, subType: string, value?: string) => {
    gtmCustomEvent("ctaClick", {
        type: "cta",
        name: name,
        sub_type: subType,
        value: value,
    });
};

export const GTMPageviewTracking = () => {
    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = () => {
            window?.dataLayer?.push({
                event: "pageview",
            });
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);
    return null;
};
