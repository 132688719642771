import { Box, Container, Flex, Link } from "@chakra-ui/react";
import type { ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import { Placeholder } from "@sitecore-jss/sitecore-jss-nextjs";
import NextLink from "next/link";
import { useHeaderBackground } from "utils/contexts/headerBackgroundContext";
import { useI18n } from "next-localization";
import LanguageSelector from "components/ui/LanguageSelector";
import SiteLogo from "components/ui/SiteLogo";

type HeaderProps = {
    params: { [key: string]: string };
    rendering: ComponentRendering;
};

const Header = (props: HeaderProps) => {
    const phKeyCenter = `header-center-${props.params?.DynamicPlaceholderId}`;
    const phKeyRight = `header-right-${props.params?.DynamicPlaceholderId}`;

    const { t } = useI18n();
    const { hasFullBackground, hasWhiteBackground, mobileNavOpen, sitename } =
        useHeaderBackground();

    return (
        <Flex
            as="header"
            sx={{
                height: ["headerMobileTopHeight", null, null, "headerTopHeight"],
                width: "full",
                position: "fixed",
                top: "0",
                zIndex: "header",
                color: !hasFullBackground || hasWhiteBackground ? "black" : "white",
                ...(!hasFullBackground && { backgroundColor: "white" }),
            }}
        >
            <Container
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={16}
                variant={"header"}
            >
                <Link
                    as={NextLink}
                    href="/"
                    sx={{
                        zIndex: "headerItem",
                        marginRight: [null, null, null, "auto"],
                        order: [1, null, null, "inherit"],
                    }}
                    aria-label={t("logoLabel")}
                >
                    <SiteLogo sitename={sitename} />
                </Link>
                <Placeholder name={phKeyCenter} rendering={props.rendering} />
                <Box
                    sx={{
                        marginLeft: "auto",
                        zIndex: "headerItem",
                        display: [mobileNavOpen ? "flex" : "none", null, null, "flex"],
                        gap: "sp8",
                        ...(mobileNavOpen && {
                            bottom: { base: "sp24", lg: "auto" },
                            left: { base: "0", lg: "auto" },
                            width: { base: "full", lg: "auto" },
                            px: { base: "sp16", lg: 0 },
                            position: { base: "fixed", lg: "static" },
                        }),
                    }}
                >
                    <Placeholder name={phKeyRight} rendering={props.rendering} />

                    <LanguageSelector fontSize={"xxs"} />
                </Box>
            </Container>
        </Flex>
    );
};

export default Header;
