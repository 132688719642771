import { IconButton } from "@chakra-ui/react";
import type { LinkField } from "@sitecore-jss/sitecore-jss-nextjs";
import NextLink from "next/link";
import { useI18n } from "next-localization";
import { gtmCTAEvent } from "components/head/GTMTracking";
import BundleIcon from "components/ui/BundleIcon";

type Fields = {
    SearchResultLink: LinkField;
};

type SearchBoxProps = {
    fields: Fields;
};

const SearchBox = (props: SearchBoxProps) => {
    const { t } = useI18n();

    if (!props.fields?.SearchResultLink.value.href) return null;
    return (
        <IconButton
            as={NextLink}
            href={props.fields?.SearchResultLink.value.href}
            aria-label={t("searchIconLabel")}
            icon={<BundleIcon name="Search" width={20} height={20} />}
            variant="unstyled"
            sx={{
                width: ["48px", null, null, "auto"],
                height: ["48px", null, null, "auto"],
                ml: [0, null, 16],
                zIndex: "headerItem",
                order: [3, null, null, "inherit"],
            }}
            onClick={() => {
                gtmCTAEvent(
                    "Search link header",
                    "Search link header",
                    props.fields?.SearchResultLink.value.href
                );
            }}
        />
    );
};

export default SearchBox;
