import { BYOCWrapper, ComponentBuilder, FEaaSWrapper } from "@sitecore-jss/sitecore-jss-nextjs";

import PartialDesignDynamicPlaceholder from "modules/PartialDesignDynamicPlaceholder";
import ContactUs from "modules/ContactUs";
import Header from "modules/Header";
import MainNavigation from "modules/MainNavigation";
import SearchBox from "modules/SearchBox";
import dynamic from "next/dynamic";

export const components = new Map();

components.set("BYOCWrapper", BYOCWrapper);
components.set("FEaaSWrapper", FEaaSWrapper);
components.set("PartialDesignDynamicPlaceholder", PartialDesignDynamicPlaceholder);
components.set("Header", {
    Default: Header,
});
components.set("SearchBox", {
    Default: SearchBox,
});
components.set("ContactUs", {
    Default: ContactUs,
});
components.set("MainNavigation", {
    Default: MainNavigation,
});

// Dynamic components
const Accordion = dynamic(() => import("modules/Accordion"));
const AccordionElement = dynamic(() => import("modules/Accordion/AccordionElement"));
const AnchorNavigation = dynamic(() => import("modules/AnchorNavigation"));
const ArticleListCarousel = dynamic(() => import("modules/ArticleListCarousel"));
const Breadcrumb = dynamic(() => import("modules/Breadcrumb"));
const Career = dynamic(() => import("modules/Career"));
const Content = dynamic(() => import("modules/Content"));
const DigitsAndData = dynamic(() => import("modules/DigitsAndData"));
const DigitsAndDataElement = dynamic(() => import("modules/DigitsAndData/DigitsAndDataElement"));
const Download = dynamic(() => import("modules/Download"));
const DynamicLinkList = dynamic(() => import("modules/DynamicLinkList"));
const Footer = dynamic(() => import("modules/Footer"));
const GridTeaserList = dynamic(() => import("modules/GridTeaserList"));
const GridTeaserElementColumns2 = dynamic(() =>
    import("modules/GridTeaserList/GridTeaserElement").then((mod) => mod.GridTeaserElementColumns2)
);
const GridTeaserElementColumns3 = dynamic(() =>
    import("modules/GridTeaserList/GridTeaserElement").then((mod) => mod.GridTeaserElementColumns3)
);
const GridTeaserElementColumns4 = dynamic(() =>
    import("modules/GridTeaserList/GridTeaserElement").then((mod) => mod.GridTeaserElementColumns4)
);
const GridTeaserElementImageLeft = dynamic(() =>
    import("modules/GridTeaserList/GridTeaserElement").then((mod) => mod.GridTeaserElementImageLeft)
);
const GridTeaserElementImageRight = dynamic(() =>
    import("modules/GridTeaserList/GridTeaserElement").then(
        (mod) => mod.GridTeaserElementImageRight
    )
);
const HeroCarousel = dynamic(() => import("modules/HeroCarousel"));
const HeroCarouselElement = dynamic(() => import("modules/HeroCarousel/HeroCarouselElement"));
const HeroBordered = dynamic(() => import("modules/Hero").then((mod) => mod.HeroBordered));
const HeroBorderedHalfSize = dynamic(() =>
    import("modules/Hero").then((mod) => mod.HeroBorderedHalfSize)
);
const HeroFullScreen = dynamic(() => import("modules/Hero").then((mod) => mod.HeroFullScreen));
const HeroFullScreenHalfSize = dynamic(() =>
    import("modules/Hero").then((mod) => mod.HeroFullScreenHalfSize)
);
const Iframe = dynamic(() => import("modules/Iframe"));
const Image = dynamic(() => import("modules/Content/Image"));
const ImageCarousel = dynamic(() => import("modules/ImageCarousel"));
const ImageCarouselElement = dynamic(() => import("modules/ImageCarousel/ImageCarouselElement"));
const Partner = dynamic(() => import("modules/Partner"));
const PartnersFilter = dynamic(() => import("modules/PartnersFilter"));
const QAList = dynamic(() => import("modules/QAList"));
const QAElement = dynamic(() => import("modules/QAList/QAElement"));
const Quote = dynamic(() => import("modules/Quote"));
const QuoteElement = dynamic(() => import("modules/Quote/QuoteElement"));
const RichText = dynamic(() => import("modules/Content/RichText").then((mod) => mod.RichText));
const RichTextBoxed = dynamic(() =>
    import("modules/Content/RichText").then((mod) => mod.RichTextBoxed)
);
const SearchResults = dynamic(() => import("modules/SearchResults"));
const Showcase = dynamic(() => import("modules/Showcase"));
const Video = dynamic(() => import("modules/Video"));

components.set("Accordion", {
    Default: Accordion,
});
components.set("AccordionElement", {
    Default: AccordionElement,
});
components.set("AnchorNavigation", {
    Default: AnchorNavigation,
});
components.set("ArticleListCarousel", {
    Default: ArticleListCarousel,
});
components.set("Breadcrumb", {
    Default: Breadcrumb,
});
components.set("Career", {
    Default: Career,
});
components.set("Content", {
    Default: Content,
});
components.set("DigitsAndData", {
    Default: DigitsAndData,
});
components.set("DigitsAndDataElement", {
    Default: DigitsAndDataElement,
});
components.set("Download", {
    Default: Download,
});
components.set("DynamicLinkList", {
    Default: DynamicLinkList,
});
components.set("Footer", {
    Default: Footer,
});
components.set("GridTeaserList", {
    Default: GridTeaserList,
});
components.set("GridTeaserElement", {
    Default: GridTeaserElementColumns2,
    Columns3: GridTeaserElementColumns3,
    Columns4: GridTeaserElementColumns4,
    ImageLeft: GridTeaserElementImageLeft,
    ImageRight: GridTeaserElementImageRight,
});
components.set("Hero", {
    Default: HeroBordered,
    BorderedHalfSize: HeroBorderedHalfSize,
    FullScreen: HeroFullScreen,
    FullScreenHalfSize: HeroFullScreenHalfSize,
});
components.set("HeroCarousel", {
    Default: HeroCarousel,
});
components.set("HeroCarouselElement", {
    Default: HeroCarouselElement,
});
components.set("IFrame", {
    Default: Iframe,
});
components.set("Image", {
    Default: Image,
});
components.set("ImageCarousel", {
    Default: ImageCarousel,
});
components.set("ImageCarouselElement", {
    Default: ImageCarouselElement,
});
components.set("QAList", {
    Default: QAList,
});
components.set("Partner", {
    Default: Partner,
});
components.set("PartnersFilter", {
    Default: PartnersFilter,
});
components.set("QAElement", {
    Default: QAElement,
});
components.set("Quote", {
    Default: Quote,
});
components.set("QuoteElement", {
    Default: QuoteElement,
});
components.set("RichText", {
    Default: RichText,
    Boxed: RichTextBoxed,
});
components.set("SearchResults", {
    Default: SearchResults,
});
components.set("Showcase", {
    Default: Showcase,
});
components.set("Video", {
    Default: Video,
});

export const componentBuilder = new ComponentBuilder({
    components: components,
});

export const moduleFactory = componentBuilder.getModuleFactory();
