import type { FC, KeyboardEventHandler } from "react";
import React, { useMemo, useRef } from "react";
import { Box, Button, Flex, Heading, Text, Link, VisuallyHidden } from "@chakra-ui/react";
import NextLink from "next/link";
import { MotionBox } from "utils/framer/motionbox";
import type { MainNavigationFieldsProps } from ".";
import { getNavigationText } from ".";
import { useRouter } from "next/router";
import BundleIcon from "components/ui/BundleIcon";
import { useI18n } from "next-localization";
import LanguageSelector from "components/ui/LanguageSelector";
import config from "temp/config";

type SubNavigationLevelProps = {
    Children?: MainNavigationFieldsProps[];
    index: number;
    parentItem?: MainNavigationFieldsProps;
    open: boolean;
    disclosureProps: any; // eslint-disable-line
    closeSubNav: () => void;
    handleLevelChange: (level: number, Children: MainNavigationFieldsProps | null) => void;
    activeIndex: number;
    activeMenuData: { level: number; fields: MainNavigationFieldsProps }[];
    isLgBreakpointOrAbove: boolean;
    subNavItemIndex?: number;
};

const SubNavigationLevel: FC<SubNavigationLevelProps> = ({
    Children,
    index,
    parentItem,
    open,
    disclosureProps,
    handleLevelChange,
    closeSubNav,
    activeIndex,
    activeMenuData,
    isLgBreakpointOrAbove,
    subNavItemIndex,
}) => {
    const { t } = useI18n();
    const router = useRouter();
    const subNavLevelRef = useRef<HTMLDivElement>(null);

    const motionVariantsStyle = isLgBreakpointOrAbove
        ? { flexShrink: 1, x: 0 }
        : { flexShrink: 0, x: "-100%" };

    const motionVariants = {
        open: {
            opacity: 1,
            x: 0,
            display: "flex",
            flexShrink: 1,
        },
        closed: {
            opacity: 0,
            ...motionVariantsStyle,
            transitionEnd: {
                display: "none",
            },
        },
    };

    const transition = {
        default: {
            ease: [0.35, 1, 0.45, 1],
            duration: isLgBreakpointOrAbove ? 0.25 : 0.5,
            delay: 0.05,
        },
    };

    let levelClass = "";

    if ((isLgBreakpointOrAbove && index === 0) || (!isLgBreakpointOrAbove && index === 1)) {
        levelClass = " second-level";
    } else if ((isLgBreakpointOrAbove && index === 1) || (!isLgBreakpointOrAbove && index === 2)) {
        levelClass = " third-level";
    }

    const navigationText =
        t(`navigationLevelOne_${subNavItemIndex}`) === `navigationLevelOne_${subNavItemIndex}`
            ? ""
            : t(`navigationLevelOne_${subNavItemIndex}`);

    const openNextSubNavLevel = (fields: MainNavigationFieldsProps) => {
        if (index !== null) {
            handleLevelChange(index + 1, fields);
        }
    };

    const renderBackButton = useMemo(() => {
        const navigateBack = () => {
            if (index !== null) {
                handleLevelChange(index - 1, null);
            }
        };

        if (parentItem) {
            return (
                <Button
                    className="backbutton"
                    variant={"unstyled"}
                    onClick={navigateBack}
                    leftIcon={
                        <BundleIcon
                            name="ArrowBackIos"
                            size={["sm", null, null, "md"]}
                            fill="black"
                        />
                    }
                >
                    {t("mobileNavigationBackButtonText")}
                    <VisuallyHidden> to {getNavigationText(parentItem)}</VisuallyHidden>
                </Button>
            );
        }
        return null;
    }, [handleLevelChange, index, parentItem, t]);

    const renderMainItem = useMemo(() => {
        if (parentItem && (!isLgBreakpointOrAbove ? index === 1 : index === 0)) {
            return (
                <Flex
                    flexDir="column"
                    flexWrap="nowrap"
                    flexBasis="100%"
                    mt={{ base: "sp16", lg: "0" }}
                    mb={"sp32"}
                >
                    <Box pt={["2", null, null, "sp16"]} pb={["2", null, null, "sp16"]}>
                        <Box>
                            <Heading
                                as="h2"
                                size="h4"
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Box as="span" pr={{ lg: "sp16" }}>
                                    {getNavigationText(parentItem)}
                                </Box>
                                <BundleIcon
                                    name="ArrowForwardIos"
                                    width={"16px"}
                                    height={"16px"}
                                    size={"sm"}
                                    display={{ base: "none", lg: "block" }}
                                />
                            </Heading>
                            {config.sitecoreSiteName === "SimCorpCom" && navigationText && (
                                <Text mt={"sp16"} pr={{ lg: "sp16" }} size="body">
                                    {navigationText}
                                </Text>
                            )}
                        </Box>
                    </Box>
                </Flex>
            );
        }
        return null;
    }, [index, isLgBreakpointOrAbove, navigationText, parentItem]);

    const renderParentItem = useMemo(() => {
        const getParentElement = () => {
            const parentElement: HTMLAnchorElement | null = document.querySelector(
                `[aria-controls='${parentItem?.Id}']`
            );
            parentElement?.focus();
        };
        const onKeyDown: KeyboardEventHandler<HTMLAnchorElement> = (e) => {
            if (e.shiftKey && e.key === "Tab") {
                e.preventDefault();
                getParentElement();
            }
        };

        if (parentItem && (!isLgBreakpointOrAbove ? index > 1 : index > 0)) {
            return (
                <Box
                    key={`${parentItem.Id}`}
                    className={`subnav__item${levelClass}${
                        parentItem.Href === router.asPath ? " subnav__item--active" : ""
                    }`}
                >
                    <Link
                        as={NextLink}
                        href={parentItem.Href}
                        onKeyDown={onKeyDown}
                        onClick={closeSubNav}
                    >
                        {getNavigationText(parentItem)}
                    </Link>
                </Box>
            );
        }
        return null;
    }, [closeSubNav, index, isLgBreakpointOrAbove, levelClass, parentItem, router.asPath]);

    const renderChildItem = (fields: MainNavigationFieldsProps) => {
        if (fields.Children && fields.Children?.length > 0) {
            const openSub = activeMenuData.some((item) => item.fields === fields);
            const inPath: boolean = fields.Href === router.asPath || openSub;
            let isInPathClass = "";
            if (activeMenuData.length === 2) {
                if (inPath) {
                    isInPathClass = " subnav__item--in-path";
                } else {
                    isInPathClass = " subnav__item--out-path";
                }
            }

            return (
                <Box
                    as="li"
                    key={`${fields.Id}`}
                    className={`subnav__item${levelClass}${isInPathClass}`}
                >
                    <Button
                        variant={"unstyled"}
                        onClick={() => openNextSubNavLevel(fields)}
                        aria-expanded={openSub}
                        aria-controls={fields.Id}
                        textAlign="left"
                        size="sm"
                    >
                        {getNavigationText(fields)}
                        <BundleIcon name="ArrowForwardIos" flex="0 0 auto" size={"sm"} />
                    </Button>
                </Box>
            );
        }

        return (
            <Box
                as="li"
                key={`${fields.Id}`}
                className={`subnav__item${levelClass}${
                    fields.Href === router.asPath ? " subnav__item--active" : ""
                }`}
            >
                <Link as={NextLink} href={fields.Href} onClick={closeSubNav}>
                    {getNavigationText(fields)}
                </Link>
            </Box>
        );
    };

    const firstLinkOrButton = subNavLevelRef.current?.querySelector(
        "a, button:not(.backbutton)"
    ) as HTMLLinkElement | HTMLButtonElement | null;

    const renderSubNavItems = () => {
        let flexBasis = "100%"; // Default value
        if (isLgBreakpointOrAbove) {
            flexBasis = index === 0 ? "66.6666%" : "33.3333%";
        }

        return (
            <MotionBox
                ref={subNavLevelRef}
                id={parentItem?.Id}
                animate={open ? "open" : "closed"}
                variants={motionVariants}
                initial="closed"
                transition={transition}
                onAnimationComplete={() => {
                    if (open && firstLinkOrButton) {
                        firstLinkOrButton?.focus();
                    }
                }}
                w={["100%", null, null, "66.66%"]}
                className={`subnav__block${activeIndex >= 1 ? " active" : ""}`}
                flexBasis={flexBasis}
                gap={100}
            >
                <Box
                    opacity={
                        !isLgBreakpointOrAbove &&
                        ((index === 0 && (activeIndex === 1 || activeIndex === 2)) ||
                            (index === 1 && activeIndex === 2))
                            ? 0
                            : 1
                    }
                    transition="opacity 0.25s ease-in-out"
                    zIndex="1"
                    width="100%"
                >
                    {renderBackButton}
                    <Box
                        display={["block", null, null, index === 0 ? "flex" : null]}
                        gap={100}
                        overflowY="auto"
                        width="100%"
                        height="100%"
                    >
                        {renderParentItem}
                        {renderMainItem}

                        <Flex
                            as="ul"
                            role="list"
                            flexDir="column"
                            flexWrap="nowrap"
                            flexBasis="100%"
                        >
                            {Children?.map((fields: MainNavigationFieldsProps) =>
                                renderChildItem(fields)
                            )}
                        </Flex>
                        {index === 0 && <LanguageSelector mt={"sp16"} fontSize={"sm"} mobile />}
                    </Box>
                </Box>
            </MotionBox>
        );
    };

    return (
        <>
            {renderSubNavItems()}
            {Children?.map((fields: MainNavigationFieldsProps, subNavItemIndex: number) => {
                const updatedIndex = index + 1;
                const matchingMenuItem = activeMenuData.some((item) => item.fields === fields);

                if (fields.Children?.length) {
                    return (
                        <SubNavigationLevel
                            key={`subNavLevel_${fields.Id}`}
                            Children={fields.Children}
                            index={updatedIndex}
                            parentItem={fields}
                            open={matchingMenuItem}
                            disclosureProps={disclosureProps}
                            closeSubNav={closeSubNav}
                            handleLevelChange={handleLevelChange}
                            activeIndex={activeIndex}
                            activeMenuData={activeMenuData}
                            isLgBreakpointOrAbove={isLgBreakpointOrAbove}
                            subNavItemIndex={subNavItemIndex}
                        />
                    );
                }
                return null;
            })}
        </>
    );
};

export default SubNavigationLevel;
