import React, { startTransition, useCallback, useEffect, useState } from "react";
import { Box, useDisclosure } from "@chakra-ui/react";
import SubNavigation from "./SubNavigation";

import { MainNavigationStyling } from "./styles";
import { useEventEmitter } from "utils/events";
import type { TextField } from "@sitecore-jss/sitecore-jss-nextjs";
import { useHeaderBackground } from "utils/contexts/headerBackgroundContext";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useI18n } from "next-localization";
import { useScroll } from "utils/hooks/useScroll";

export const critical = true;

export interface MainNavigationFieldsProps {
    Id: string;
    DisplayName: string;
    Title: TextField;
    NavigationTitle: TextField;
    Href: string;
    Querystring: string;
    Children?: Array<MainNavigationFieldsProps>;
    Styles: string[];
}

export type MainNavigationProps = {
    fields: MainNavigationFieldsProps;
    params?: { [key: string]: string };
    handleClick: (event?: React.MouseEvent<HTMLElement>) => void;
    relativeLevel: number;
};

export const getNavigationText = (props: MainNavigationFieldsProps): string => {
    if (props?.NavigationTitle?.value) {
        return String(props.NavigationTitle.value);
    } else if (props.Title) {
        return String(props.Title.value);
    } else {
        return String(props.DisplayName);
    }
};

const MainNavigation = (props: MainNavigationProps) => {
    const { t } = useI18n();
    const [activeNavChildren, setActiveNavChildren] = useState<MainNavigationFieldsProps[]>([]);

    const { getDisclosureProps, isOpen, onOpen, onClose } = useDisclosure();
    const disclosureProps = { ...getDisclosureProps(), onClose };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventEmitter = useEventEmitter<any>("navigation");
    const { hasFullBackground, setHasWhiteBackground, setMobileNavOpen } = useHeaderBackground();

    const ref = useDetectClickOutside({ onTriggered: () => onClose() });
    const [scrolled, setScrolled] = useState<boolean>(false);

    useEffect(() => {
        const bodyElement = document.body;

        bodyElement.classList.toggle("nav--open", isOpen);
        bodyElement.style.height = isOpen ? "100%" : "";
        bodyElement.style.overflow = isOpen ? "hidden" : "";

        setHasWhiteBackground(scrolled || isOpen);
        setMobileNavOpen(isOpen);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const updatePosition = useCallback(() => {
        startTransition(() => {
            setScrolled(window.scrollY > 100);
            setHasWhiteBackground(window.scrollY > 100 || isOpen);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useScroll(updatePosition);

    const handleFirstLevelClick = useCallback(
        (fields: MainNavigationFieldsProps | undefined) => {
            setActiveNavChildren(fields?.Children ?? []);
            onOpen();
            eventEmitter.emit("onNavigationOpen", true);
        },
        [eventEmitter, onOpen]
    );

    return (
        <>
            {hasFullBackground && (
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width={"full"}
                    height={"full"}
                    bg="white"
                    zIndex={1}
                    transition={"transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1)"}
                    transform={isOpen || scrolled ? "translateY(0)" : "translateY(-100%)"}
                />
            )}

            <Box
                as="nav"
                aria-label={t("mainNavigationAriaLabel")}
                ref={ref}
                className={`mainnav ${isOpen ? "mainnav--active" : ""}`}
                {...MainNavigationStyling}
            >
                <SubNavigation
                    navChildren={Array.from(Object.values(props.fields))}
                    activeNavChildren={activeNavChildren}
                    disclosureProps={disclosureProps}
                    isOpen={isOpen}
                    handleFirstLevelClick={handleFirstLevelClick}
                    navRef={ref}
                />
            </Box>
        </>
    );
};

export default MainNavigation;
