/**
 * This Layout is needed for Starter Kit.
 */
import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-nextjs";
import type { HTMLLink, LayoutServiceData } from "@sitecore-jss/sitecore-jss-nextjs";
import { Box, VisuallyHidden } from "@chakra-ui/react";
import Favicon from "./components/head/Favicon";
import ReactIconBundle from "./assets/icons/icons.sprite.svg";
import MetaData from "./components/head/MetaData";
import { HeaderBackgroundProvider } from "utils/contexts/headerBackgroundContext";
import { GTMPageviewTracking } from "components/head/GTMTracking";
import { transparentize } from "polished";
import ScrollProgress from "components/ui/ScrollProgress";
import SkipLink from "components/ui/SkipLink";
import Script from "next/script";
import { getShortLangCode } from "utils/localehelpers";
import Scripts from "./Scrips";

interface LayoutProps {
    layoutData: LayoutServiceData;
    vercelDomain?: string;
    headLinks: HTMLLink[];
}

const Layout = (props: LayoutProps) => {
    const sitename = props.layoutData.sitecore.context.site?.name;
    const { route, context } = props.layoutData.sitecore;
    const { pageEditing, language } = context;

    // @ts-expect-error: fields.Key.value is not declared in sitecores own types
    const hasWhiteText = route?.fields?.HeaderColor?.fields.Key?.value === "white";
    const countryCode = globalThis.document?.cookie.match(/countryCode=([A-Z]{2})/)?.[1];
    const languageCode = getShortLangCode(language);

    return (
        <>
            <Scripts />
            <MetaData
                {...route?.fields}
                MetadataCanonicalUrl={`${!props.vercelDomain?.includes("http") ? "https://" : ""}${
                    props.vercelDomain
                }${props.layoutData.sitecore.context.itemPath as string}`}
            />

            {props.headLinks.map((headLink) => (
                <link rel={headLink.rel} key={headLink.href} href={headLink.href} />
            ))}

            <Favicon />

            {!pageEditing && <GTMPageviewTracking />}

            {/* root placeholder for the app, which we add components to using route data */}
            <Box
                pt={[
                    "var(--sizes-headerMobileTopHeight)",
                    null,
                    null,
                    "var(--sizes-headerTopHeight)",
                ]}
            >
                <SkipLink />
                <ScrollProgress />
                <HeaderBackgroundProvider hasFullHero={hasWhiteText} sitename={sitename ?? ""}>
                    {route && <Placeholder name="headless-header" rendering={route} />}
                </HeaderBackgroundProvider>
                <Box
                    as="main"
                    id="main-content"
                    bg="white"
                    boxShadow={`0px 50px 56px 0px ${transparentize(0.8, "black")}`}
                    position="relative"
                    zIndex={1}
                >
                    {route && <Placeholder name="headless-main" rendering={route} />}
                </Box>

                {route && <Placeholder name="headless-footer" rendering={route} />}
            </Box>

            <VisuallyHidden id="icon-sprite" aria-hidden="true">
                <ReactIconBundle />
            </VisuallyHidden>

            {countryCode !== "US" && (
                <Script
                    strategy="afterInteractive"
                    id="CookieConsent"
                    src="https://policy.app.cookieinformation.com/uc.js"
                    data-culture={languageCode}
                    data-gcm-version="2.0"
                    type="text/javascript"
                />
            )}
        </>
    );
};

export default Layout;
