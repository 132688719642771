import { Icon, forwardRef } from "@chakra-ui/react";
import type { IconProps } from "@chakra-ui/react";

export interface BundleIconProps extends IconProps {
    name:
        | "SimcorpFullLogo"
        | "SimcorpTypeLogo"
        | "SofiaLogo"
        | "ArrowForwardIos"
        | "Add"
        | "ArrowBackIos"
        | "ArrowForward"
        | "Check"
        | "Close"
        | "Download"
        | "ExpandMore"
        | "Pause"
        | "PlayArrow"
        | "Remove"
        | "Search"
        | "Verified"
        | "Location"
        | "Label";
    size?: string | (string | number | null)[] | number;
}

const BundleIcon = forwardRef<BundleIconProps, "svg">(({ name, ...props }, ref) => {
    return (
        <Icon {...props} ref={ref} aria-hidden="true">
            <use xlinkHref={`#sc-${name}`} />
        </Icon>
    );
});
export default BundleIcon;
